@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: rgb(249, 250, 251);
  text-decoration: none;
}

/* ################################################### */
/* Custom scrollbar */
/* ################################################### */
/* Firefox */
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(65, 150, 12); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid #dfdfe1; /* creates padding around scroll thumb */
}

.slick-next,
.slick-prev {
  border: none;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute !important;
  background-color: transparent !important;
  bottom: -4px !important;
}

.slick-dots li {
  width: 5px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.85;
  color: rgb(255, 255, 255) !important;
}

.shadow_custom{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.p-datepicker-trigger{
  background-color: rgb(65, 150, 12) !important;
}

#pr_id_2_content > div > form > div:nth-child(2) > div.w-full.flex.flex-col.gap- > span > button{
}